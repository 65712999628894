(function(){
    WebFontConfig = {
        google: { families: [ 'Open+Sans:400,300,300italic,400italic:latin' ] }
    };
    (function() {
        var wf = document.createElement('script');
        wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        wf.type = 'text/javascript';
        wf.async = 'true';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wf, s);
    })();
})();

var transitionend = (function (transition) {
    var transEndEventNames = {
        'WebkitTransition': 'webkitTransitionEnd',// Saf 6, Android Browser
        'MozTransition': 'transitionend',      // only for FF < 15
        'transition': 'transitionend'       // IE10, Opera, Chrome, FF 15+, Saf 7+
    };

    return 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';
})(Modernizr.prefixed('transition'));

(function ($) {

    var $scroll, $body, $btnNav, $nav, $siteWrapper, clickEvent = 'click tap',
        bodyClick = (navigator.userAgent.match(/ipad|iphone/i)) ? 'touchstart' : clickEvent;

    $(document).ready(function () {
        $scroll = $('html, body');
        $body = $('body');
        $btnNav = $('.js-btn-nav');
        $nav = $('.js-nav-main');
        $siteWrapper = $('.js-site-wrapper');
        var $window = $(window);

        var clickEvent = 'click', skrollrOptions = {
            smoothScrolling: false,
            forceHeight: false,
            constants: {
                //fill the box for a "duration" of 150% of the viewport (pause for 150%)
                //adjust for shorter/longer pause
                box: '150p'
            }
        };

        $body
            .addClass('is-ready')
            .on(clickEvent, '.js-cookie-close', function(){
                var d = new Date();
                d.setTime( d.getTime() + (365*24*60*60*1000) );
                document.cookie = "as_cookie=1; expires=" + d.toUTCString() + "; path=/";
                $(this).parents('.js-cookie-policy').slideUp(400, function(){
                    $(this).remove();
                });
                return false;
            })
            .on(bodyClick, '.js-btn-nav', function(e){
                $(this).toggleClass('is-active');
                $nav.toggleClass('is-active');
                $siteWrapper.toggleClass('is-nav-active');
                e.stopPropagation();
                return false;
            })
            .on(bodyClick, '.js-nav-main', function(e){
                e.stopPropagation();
            })
            .on(clickEvent, '.js-next-arrow', function(){
                var $next = $(this).parents('.js-home-section').next('.js-home-section');
                $scroll.animate({
                    scrollTop: $next.offset().top
                });
                return false;
            })
            .on(bodyClick, function(){
                if ($nav.hasClass('is-active')) {
                    $btnNav.removeClass('is-active');
                    $nav.removeClass('is-active');
                    $siteWrapper.removeClass('is-nav-active');
                }
            })
            .on(clickEvent, '.wpcf7-not-valid-tip', function(){
                $(this).siblings('input').select().end().remove();
            })
            .on(transitionend, '.js-preload', function(){
                $(this).addClass('is-off');
            })
            .on(clickEvent, '.js-close', function() {
                $overlay.removeClass('is-active');
                $(this).parent().removeClass('is-active');
            })
            .on(clickEvent, '.js-person-link', function(e){

                e.preventDefault();
                var $people_list = $('.people-list');
                var $parent = $(this).closest('li');
                var $detail = $parent.find('.person-expand');

                var $elem = $('.js-person-expand[data-person-id="' + $(this).attr('data-person-id') + '"]').addClass('is-active');

                var $people_list_offset_top = $people_list.offset().top;
                var $people_list_height = $people_list.height();

                var $scroll_content = $('.person-expand__content', $detail);
                var $scroll_content_offset_top = $scroll_content.offset().top;

                var $view_top = $('.site-header').height();
                if($('#wpadminbar').length){
                    $view_top += 31;
                }
                var $bound = $detail.get(0).getBoundingClientRect();
                var $top = $detail.offset().top - $view_top;

                var $expander_inner = $detail.find('.person-expand__wrapper');
                var $padding_top = ($window.height() - $view_top)/2 - $expander_inner.height()/2;
                if($padding_top < 0){
                    $padding_top = 0;
                }
                $expander_inner.css('padding-top', $padding_top);

                var $new_height = $expander_inner.height() - $padding_top;

                $('.person-expand__content', $detail).height($new_height);

                if($.fn.mCustomScrollbar){
                    $('.scroll-content', $detail).mCustomScrollbar('update');
                }

                $('html, body').animate({
                    scrollTop: $top
                }, 600);

                setTimeout(function(){
                    $elem.addClass('is-visible');
                }, 0);

            })
            .on(clickEvent, '.js-person-close', function(e){
                e.preventDefault();
                function remove() {
                    $elem.removeClass('is-visible');
                }
                var $elem = $(this).parents('.js-person-expand').removeClass('is-visible');
                Modernizr.csstransitions ? $elem.one(window.transitionEnd, remove) : remove();
                return false;

            });

        if ($body.hasClass('home')) {
            (function () {
                var $hold = $('.js-home-bg-hold'), $slides = $('.js-home-slide'), $current = $slides.first().addClass('is-active');

                var holdImage = function($element) {
                    $element.find('.js-home-bg').one(transitionend, function () {
                        $hold.css('background-image', $current.find('.js-home-bg').css('background-image'));
                    });
                };

                holdImage($current);

                var next = function() {
                    var $next = $current.next('.js-home-slide').length > 0 ? $current.next('.js-home-slide') : $slides.first();

                    $current.removeClass('is-active');
                    $current = $next.addClass('is-active');

                    holdImage($current);
                };

                setInterval(next, 9000);

            })();
        }

        $window.on('scroll.pin_header', function(){
            var $top = $window.scrollTop();

            if($top > 5){
                $body.addClass('scrolled');
            }else{
                $body.removeClass('scrolled');
            }

        }).trigger('scroll.pin_header');

        $('.stage-processes__item').bind('item_offset', function(){
            var $t = $(this);
            var $offset = $t.offset();

            $t.data('offset', $offset);
        }).trigger('item_offset')
        .bind('item_width', function(){
            var $t = $(this);
            var $left_width = $t.find('.stage-processes_item-left').width();
            var $right_width = $t.find('.stage-processes_item-right').width();

            return $left_width + $right_width;
        }).bind('active', function(){
            var $t = $(this);
            var $container = $t.closest('.stage-processes__items');
            var $count = $('.stage-processes__item').length;

            $t.addClass('active');

            var $item_width = $t.triggerHandler('item_width');
            var $item_offset = $t.data('offset');
            var $left = ($window.width()/2 - $item_width/2) - $item_offset.left;
            if($left > 0)
                $left = 0;

            $container.css('margin-left', $left);

            var $index = $t.index();
            $('.stage-processes__background').eq($index).addClass('active');

        }).bind('deactive', function(){
            var $t = $(this);
            var $container = $t.closest('.stage-processes__items');

            $t.removeClass('active');
            $container.removeClass('is-shifted');

            var $index = $t.index();
            $('.stage-processes__background').eq($index).removeClass('active');
        });

        $body.on('click touchstart', '.stage-processes__item-left a', function(){
            var $t = $(this);
            var $item = $t.closest('.stage-processes__item');

            if($item.hasClass('active')){
                $item.trigger('deactive');
            }else{
                $item.prevAll().trigger('deactive');
                $item.nextAll().trigger('deactive');
                $item.trigger('active');
            }

            return false;
        });

    });

})(jQuery);
